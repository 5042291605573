.digital-form-tab {

  .progress-steps{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .tabs-container {
    margin-top: 20px;
    display: flex;
    border-bottom: 2px solid white;

    .tab {
      cursor: pointer;
      margin-right: 2rem;
      font-weight: 500;
      color: var(--core-primary-50, rgba(37, 55, 70, 0.50));
      //display: none;
    }
    .active {
      //display: initial;
      font-weight: 700;
      position: relative;
      color: var(--Core_Primary-dark-blue, #253746);
      .divider{
        position: absolute;
        top: 24px;
        height: 3px;
        width: 100%;
        background-color: var(--Secondary-Yellow);
        border: 1px solid var(--Secondary-Yellow);
        border-radius: 5px;
      }
    }
    .tab0{
      display: initial;
    }
    .tab1{
      display: initial;
    }
    .tab2{
      display: initial;
    }
    .tab3{
      display: initial;
    }
    .tab4{
      display: initial;
    }
  }

  .tab-content-wrapper {
    margin-top: 20px;
    display: flex;
    gap: 20px;

    .tab-content {
      padding: 28px;
      border-radius: 4px;
      background: #FFF;
      box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);
      height: max-content;
    }

    @media screen and (min-width:1920px) {
      .tab-content {
        padding: 28px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);
      }
    }


    .notepad{
      width:35%;
      .np-header {
        padding: 14px 98px !important;
      }
    }
    .np-header{
      margin-bottom: 0;
      padding: 4px 10px 4px 10px;
      border-radius: 4px 4px 0 0;
      background: var(--Secondary-Yellow, #FFB81D);
    }

    .np-container{
      padding:0;
      border-radius: 0 0 4px 4px;
      height: 580px;
      overflow-y: auto;
      overflow-x: auto;

      .np-textarea{
        height: 580px;
      }
    }
  }



  .start-request-btn {
    button {
      margin: 52px 0;
      padding: 12px 24px;
      border: none;
      border-radius: 4px;
      background: var(--Blue-7710C);
      color: var(--white);
      font-size: 15px;
      font-weight: 600;
      line-height: 136%; /* 20.4px */
    }

    .disabled {
      background: gray;
      cursor: not-allowed;
    }
  }
}
