.digital-form-work-details {
  background-color: #FFFFFF;
  padding: 28px;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  .form-class {
    display: flex;
    flex-direction: column;
    //align-items: flex-start;
    gap: 16px;

    .form-error{
      color: red;
      font-size: 11px;
    }

    div {
      label {
        /* display: flex; */
        margin: 0;
        color: var(--Core_Primary-dark-blue);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 17.68px */

        img{
          margin-left: 12px;
          cursor: pointer;
        }
      }

      input {
        //width: 100%;
        display: flex;
        padding: 11px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        /* border: 1px solid var(--gray-field-stroke); */
        border: 1px solid lightgray;
      }

      input[type=text] {
        width: 100%;
      }
    }

    .text-editor-quill {
      .ql-container {
        min-height: 110px;
      }


    }


    .radio-button {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      div {
        display: flex;
        flex-direction: row;
        align-items: end;

        input {
          width: 20px;
          height: 20px;
          border-radius: 12px;
          border: 1px solid var(--core-primary-50);
        }

        input[type="radio"]:checked:after {
          width: 20px;
          height: 20px;
          border-radius: 15px;
          position: relative;
          /* background-color: var(--Blue-7710C); */
          background: var(--Blue-7710C, #00a6b6);
          content: "";
          display: inline-block;
          visibility: visible;
        }
        
      }
    }

    //.checkbox-list {
    //  //height: 262px;
    //  .checklist-wrapper {
    //    display: flex;
    //    flex-direction: column;
    //    padding: 16px 12px 16px 20px;
    //    align-items: flex-start;
    //    gap: 4px;
    //    border: 1px solid var(--gray-field-stroke, #d5dee4);
    //    border-radius: 6px;
    //
    //    div{
    //
    //      .work-scope{
    //
    //        input {
    //          margin-right: 12px;
    //          width: 20px;
    //          height: 20px;
    //          border-radius: 2px;
    //          border: 1px solid var(--core-primary-50, rgba(37, 55, 70, 0.5));
    //        }
    //
    //        label {
    //          display: flex;
    //          /* align-items: center; */
    //          width: 100%;
    //        }
    //      }
    //    }
    //  }
    //}

    .checkbox-list{
      .checklist-wrapper{
        //height: 200px;
        border: 1px solid #d5dee4;
        border-radius: 4px;
        padding: 16px 12px 16px 20px;

        .form-check{
          padding: 0;
        }

        //input{
        //  width:20px;
        //  //heigh: 20px;
        //}
        //&:checked {
        //  background: var(--Blue-7710C, #00a6b6);
        //}

        //input {
        //  width: 20px;
        //  height: 20px;
        //  border-radius: 2px;
        //  //background: #00a6b6;
        //}

        //div {
        //  display: flex;
        //  align-items: end;
        //  gap: 12px;

          //label {
          //  color: var(--error-red, #d94467);
          //  font-size: 15px;
          //  font-style: normal;
          //  font-weight: 400;
          //  line-height: 136%; /* 20.4px */
          //}

        .custom-check{
          display: flex;
          gap: 12px;
          align-items: center;
          width: 100%;
          padding: 3px 16px;
        }
        .custom-check label{
          display: flex;
          flex-wrap: wrap;
          border-radius: 2px;
          flex: 1 1 auto !important;
          font-weight: 400;
          color: #7B92A5;

        }
        .custom-check input{
          width: 20px;
          height: 20px;
          padding: 0 !important;
          background: #fff;
          border-radius: 2px !important;
        }

        .custom-check input:checked {
          background: #00a6b6 !important;
        }
          .checkbox-label {
            display: block;
            margin-bottom: 10px;
            position: relative;
          }

          .checkbox-label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkbox-label:after {
            border-radius: 2px;
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            /* border: 2px solid #000; */
            background-color: #fff;
          }

          .checkbox-label.checked:after {
            background: var(--error-red, #d94467);
          }
        //}


      }
    }

    .toggle {
      margin-bottom: 30px;
      .switch {
        position: relative;
        display: inline-block;
        //width: 60px;
        height: 34px;

      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        width: 60px;
        position: absolute;
        cursor: pointer;
        top: 35px;
        left: 0;
        right: 10px;
        bottom: -34px;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background: var(--Blue-7710C, #00a6b6);
      }

      //input:focus + .slider {
      //  background: var(--Blue-7710C, #00a6b6);
      //}

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    button {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      /* background: var(--Blue-7710C); */
      background: var(--Blue-7710C, #00a6b6);
      border: none;
      color: #ffffff;
    }
  }
}
.additional-inactive-info {
  opacity: 0.4 !important;
  pointer-events: none !important;

  //.custom-toolbar-class {
  //  display: flex;
  //  justify-content: space-between;
  //}
  //
  //.custom-wrapper-class {
  //  border: 1px solid #ddd; /* Add borders or other styles as needed */
  //  padding: 10px;
  //}
  //
  .custom-editor-class {
    min-height: 200px; /* Set minimum height for the editor */
  }
}

.additional-active-info {
//
//  .custom-toolbar-class {
//    display: flex;
//    gap:10px;
//    //justify-content: space-between;
//    .rdw-inline-wrapper{
//      display: flex;
//      gap:10px
//    }
//    .rdw-list-wrapper{
//      display: flex;
//      gap:4px
//    }
//    .rdw-text-align-wrapper{
//      display: flex;
//      gap:4px
//    }
//    .rdw-link-wrapper{
//      display: flex;
//      gap:4px
//    }
//  }
//
//  .custom-wrapper-class {
//    border: 1px solid #ddd; /* Add borders or other styles as needed */
//    padding: 10px;
//  }
//
  .custom-editor-class {
    min-height: 200px; /* Set minimum height for the editor */
  }
}



