.digital-form-welcome {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #FFFFFF;
  padding: 28px;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  .welcome-content{
    height: 100%;
    //position: relative;

    .instructions{
      //position: absolute;
      //top: 0;
    }

    .gap-div{
      width: 100%;
      height: 200px;
    }

    .download-file{
      width: 100%;
      //position: absolute;
      //bottom: 0;

      .download-manual{
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: #F1F6F6;

        .pdf-file {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .file-name-size {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2px;

          .file-name {
            color: var(--Core_Primary-dark-blue);
            font-weight: 600;
          }

          .file-size {
            color: var(--gray_text-form_placeholder);
          }
        }
      }
    }
  }

  h2 {
    color: var(--Core_Primary-dark-blue);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%; /* 32.64px */
  }

  p {
    color: var(--cool_gray-body_text);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.4px;
  }

  span{
    color: var(--cool_gray-body_text);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.68px;
  }

}

