.Manager-Wrapper{
  padding: 28px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgba(58, 70, 84, 0.12);

  .Manager-Type{
    font-weight: 700;
    font-size: 15px;
  }

  .manager-description{
    font-size: 12px;
  }

  .manager-dropdown-list{
    width: 100%;

    select{
      width: 100%;
      padding: 6px 12px;
    }
  }

  .action-buttons{
    display: flex;
    flex-direction: row;
    gap: 20px;

    .btn-assign{
      background-color: #273E61;
      color: var(--white);
      padding: 12px 24px;
      border: none;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      line-height: 136%;
    }

    .btn-cancel{
      background-color: #66094E;
      color: var(--white);
      padding: 12px 24px;
      border: none;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      line-height: 136%;
    }
  }
}
