.bread-class a{
  color: #00A6B6;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%; /* 20.4px */
  text-transform: capitalize;
}
.active-breadcrumb {
  color: #00A6B6 !important;
}
.breadcrumb-link {
  color: grey !important;
}
