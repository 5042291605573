.Building-search{
  background-color: #FFFFFF;
  padding: 28px;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  .errorText {
    display: block;
    color: #ff0000;
    font-size: 12px;
    border: none;
  }
}

