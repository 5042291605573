.Step-Block{
  height: 68px;
  width: 163px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  padding-right: 12px;
  border: 1px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  .left-color-block{
    width: 10%;
    height: 100%;
    background-color: #00A6B6;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .left-no-color-block{
    width: 10%;
    height: 100%;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .content-block{
    width: 80%;
    color: var(--Core_Primary-dark-blue);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.68px;
    text-transform: capitalize;
  }
}

.Step-Block.current{
  background: #F1F6F6;
}
