.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.drop-zone {
  border: 1px dashed #ffb81d;
  padding: 1.5rem;
  border-radius: 20px;
  margin-top: 0.5rem;
  cursor: pointer;
  .file-container {
    @extend .flex-center;
    flex-direction: column;
    gap: 0.3rem;
    .description {
      font-size: 15px;
      font-weight: 600;
    }
    .browse-content {
      font-size: 13px;
      font-weight: 400;
      span {
        color: #00a6b6;
        font-weight: 600;
      }
    }
    p {
      margin: 0;
    }
    .icon {
      @extend .flex-center;
      background-color: #fff9e9;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      svg {
        color: #ffb81d;
        width: 25px;
        height: 25px;
      }
    }
  }
}
.file-list {
  max-height: 250px;
  overflow-y: auto;
  .file {
    border-radius: 4px;
    background: #f1f6f6;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    .file-name-container {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      .file-name {
        margin: 0;
        font-weight: 600;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .user-name {
        color: blue
      }
      .last-updated {
        font-size: 9px;
        margin: 0;
        color: #7b92a5;
      }
    }
    .icon {
      color: #68000d;
      cursor: pointer;
    }
    .downloadIcon {
      color: #ffb81d;
      cursor: pointer;
    }
  }
}
.spinner {
  width: 20px;
  height: 20px;
}
.fileType {
  @extend .flex-center;
  height: 35px;
  width: 35px;
  border-radius: 12px;
  svg {
    color: white;
  }
}
.image {
  background-color: #d8d4e1;
}
.video,
.csv,
.xls {
  background-color: #c4ebdd;
}
.word {
  background-color: #50b5ff;
}
.ppt {
  background-color: #fce6a6;
}
.default {
  background-color: #fff9e9;
  svg {
    color: #ffb81d;
  }
}
.pdf {
  background-color: #f5523c;
}
