.psiPageWrapper{
  //padding-left: 2rem;

  .SubHeading{
    font-weight: 400;
    font-size: 15px;
    line-height: 20.4px;
  }

  .DescriptiveButtonsContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 31px;

    .dtb-wrapper{
      width: 340px;
      box-shadow: 0px 10px 20px 0px #3A46541F;
    }

    .top-content{
      background-color: var(--white-shade);
    }

    .title{
      margin-top: auto;
      margin-bottom: auto;
      font-size: 15px;
      font-weight: 600;
      line-height: 20.4px;
      color: var(--Core_Primary-dark-blue);
    }

    .description{
      font-size: 13px;
      font-weight: 400;
      color: var(--cool_gray-body_text);

    }

    .classification{
      display: none;
    }
  }

  .active-completed-requests{
    margin-top: 44px;

    .Requests-Headings{
      color: var(--Core_Primary-dark-blue);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.76px; /* 21.76px */
    }

    .request-tiles{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    @media screen and (min-width: 1920px){
      .request-tiles{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
      }
    }

    //@media screen and (min-width: 1920px){
    //  .request-tiles{
    //    display: grid;
    //    grid-template-columns: repeat(2, 1fr);
    //    gap: 16px;
    //  }
    //}
  }
}
