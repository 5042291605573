.theme-io {
  .vertical-menu {
    .navbar-brand-box {
      padding-left: 0;

      .logo-lg {
        img {
          height: 40px;
        }
      }
    }
  }

  .container-fluid {
    padding-left: 1.5rem;
  }
}
