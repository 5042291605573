:root {
  --core-primary-50: rgba(37, 55, 70, 0.50);
  --Secondary-Yellow: #FFB81D;
  --Core_Primary-dark-blue: #253746;
  --cool_gray-body_text: #54565B;
  --gray-field-stroke: #D5DEE4l;
  --gray_text-form_placeholder: #7B92A5;
  --Blue-7710C: #00A6B6;
  --white: #FFF;
  --white-shade: #F1F6F6
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url('./assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF');
}

@font-face {
  font-family: "San Francisco B";
  font-weight: 700;
  src: url('./assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBOLD.OTF');
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
  font-family: "San Francisco", sans-serif !important;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


