.custom-descriptive-button{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 192px;
  width: 400px;
  border-radius: 5px;
  margin-bottom:50px;
  cursor: pointer;

  /* shadow-step-card */
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  .left-color-bar{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #5CCFA6;
    height: 100%;
    width: 16px;
  }
  .left-color-grey-bar{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #959595;
    height: 100%;
    width: 16px;
  }

  .right-content-area{
    width: 100%;

    .top-content{
      display: flex;
      width: 100%;
      height: 110px;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      background-color: #EFF5F5;

      .top-left-content{

        .request-heading{
          color: var(--Core_Primary-dark-blue);
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20.4px; /* 20.4px */
          display: block;
        }

        .top-span-content-div{

          margin: 0;
          padding: 0;
          box-sizing: border-box;
          display: block;

          .content-span{
            color: var(--gray_text-form_placeholder);
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14.96px; /* 14.96px */
            width: 112px;
          }

          .content-value{
            color: var(--Core_Primary-dark-blue);
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 14.96px; /* 14.96px */
            margin-left: 10px;
          }
        }
      }

      .top-right-content{
        .request-number{
          color: #253746;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 17.68; /* 17.68px */
          text-transform: capitalize;
        }
      }
    }

    .mid-content{
      width: 100%;
      border-bottom: 1px solid #D5DEE4;
      padding-left: 16px;
      padding-right: 12px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      .building-code{
        display: inline-grid;
        padding: 10px 0px 10px 0px;
        //border-right: 1px solid #D5DEE4;
        gap: 6px;


        .content-span{
          color: var(--gray_text-form_placeholder);
          font-size: 11px;
          text-align: left;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 12.1px */
        }

        .content-value{
          color: var(--Core_Primary-dark-blue);
          text-align: left;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 14.3px */
        }
      }

      .changes{
        display: inline-grid;
        padding: 10px 0px 10px 0px;
        //border-right: 1px solid #D5DEE4;
        width: 70px;
        gap: 6px;


        .content-span{
          color: var(--gray_text-form_placeholder);
          font-size: 11px;
          text-align: left;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 12.1px */
        }

        .content-value{
          color: var(--Core_Primary-dark-blue);
          text-align: left;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 14.3px */
        }
      }

      .last-updated{
        display: inline-grid;
        padding: 10px 0px 10px 0px;
        //border-right: 1px solid #D5DEE4;
        width: max-content;
        gap: 6px;


        .content-span{
          color: var(--gray_text-form_placeholder);
          font-size: 11px;
          text-align: left;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 12.1px */
        }

        .content-value{
          color: var(--Core_Primary-dark-blue);
          text-align: left;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 14.3px */
        }
      }

      .divider{
        width: 1px;
        background-color: #D5DEE4;
      }
    }

    .lower-content{
      width: 100%;
      padding-left: 16px;
      align-items: center;

      .team-name{
        color: var(--cool_gray-body_text);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 12.1px */

        .accepted{
          color: var(--Blue-7710C, #00A6B6);
          font-weight: 600;
        }
      }
    }
  }
}
