.attachment-wrapper {

  .attachment-upper-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .attachment-title {
      color: var(--Core_Primary-dark-blue, #253746);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 136%; /* 20.4px */
    }

    .attachment-description {
      color: var(--cool_gray-body_text, #54565b);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 136%; /* 17.68px */
    }
  }

  .attachment-lower-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    h2 {
      color: #253746;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 136%;
    }

    form {
      display: flex;
      width: 903px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      input {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background: var(--error-red, #d94467);
      }

      div {
        display: flex;
        align-items: end;
        gap: 12px;

        label {
          color: var(--error-red, #d94467);
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 136%; /* 20.4px */
        }

        .checkbox-label {
          display: block;
          margin-bottom: 10px;
          position: relative;
        }

        .checkbox-label input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        .checkbox-label:after {
          border-radius: 2px;
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          /* border: 2px solid #000; */
          background-color: #fff;
        }

        .checkbox-label.checked:after {
          background: var(--error-red, #d94467);
        }
      }

      button {
        display: inline-flex;
        padding: 12px 24px;
        align-items: center;
        gap: 12px;
        border: none;
        border-radius: 4px;
        background: var(--Blue-7710C, #00a6b6);
        color: #fff;
      }
    }
  }
}

.submission-succes {
  border-radius: 4px;
  border: 1px solid #d5dee4;

  .submission-icon {
    text-align: center;
    margin-top: 28px;
  }

  h2 {
    margin-top: 20px;
    color: var(--blue-cta, #273e61);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%; /* 20.4px */
  }

  p {
    margin-top: 20px;
    color: var(--cool_gray-body_text, #54565b);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
  }

  #task_id {
    margin-top: 8px;
    margin-bottom: 28px;
    color: var(--cool_gray-body_text, #54565b);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    span {
      color: var(--Blue-7710C, #00a6b6);
      font-weight: 700;
    }
  }
}
.inner-file-container{
  background-color: #FFFFFF;
  padding: 28px;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);
}

.file {
  border-radius: 4px;
  background: #f1f6f6;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  .file-name {
    font-size: 13px;
    font-weight: 600;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;

  }
  .file-created{
    color: #00A6B6;
    font-size: 13px;
    margin-bottom: 0;
  }
  .icon {
    color: #68000d;
    cursor: pointer;
  }
}
