.Main-Wrapper{
  background-color: #F8F8FB;
  padding: 5px;

  .Top-Wrapper{
    background-color: #FFFFFF;
    padding: 28px;
    box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

    span{
      font-size: 15px;
      font-weight: 600;
      line-height: 20.4px;
    }

    .Task-Details{
      margin-top:20px;
      padding: 20px 12px 20px 12px;
      border: 1px solid #D5DEE4;
      border-radius: 4px;

      .label-item{
        width: 100%;
        display: flex;
        align-items: center;

        label{
          width: 40%;
          color: var(--cool_gray-body_text);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 136%; /* 17.68px */
          margin: 0;
        }
        h2{
          color: var(--Core_Primary-dark-blue);
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 136%; /* 17.68px */
          margin:0;
        }
      }
      hr{
         width: 100%;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
    .acknowledgment-class{
      margin-top: 40px;

      p{
        font-size: 13px;
        font-weight: 400;
        line-height: 136%;
      }
      .Acknowledge-Button {
        background-color: #66094E;
        color: var(--white);
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        line-height: 136%;
      }
    }
    .acknowledged-class{
      margin-top: 20px;

      p{
        font-size: 13px;
        font-weight: 400;
        line-height: 136%;
      }
    }
  }

  .Bottom-Wrapper{
    margin-top:20px;
    background-color: #FFFFFF;
    padding: 28px;
    box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

    .Next-Step{
      margin-top: 20px;
      background-color: #FFFFFF;
      padding: 28px;
      border: 1px solid #D5DEE4;
      border-radius: 4px;

      .label-item {
        width: 100%;
        display: flex;
        align-items: center;

        label {
          width: 40%;
          color: var(--cool_gray-body_text);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 136%; /* 17.68px */
        }

        h2 {
          color: var(--Core_Primary-dark-blue);
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 136%; /* 17.68px */
        }
      }
    }
    div{
      margin-top: 20px;

      button{
        background-color: #273E61;
        color: var(--white);
        padding: 6px 12px;
        border: none;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        line-height: 136%;
      }
    }
  }
  .available-actions-wrapper{
    margin-top: 40px;

    .top-p{
      p{
        color: #253746;
        font-size: 13px;
        font-weight: 400;
        line-height: 136%;
      }
    }

    .available-actions-buttons{
      display: inline-flex;
      align-items: center;
      gap: 20px;

      .btn-close-task{
        background-color: #66094E;
        color: var(--white);
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        line-height: 136%;
      }

      .btn-assign-pm, .btn-assign-fma, .btn-assign-move-manager{
        background-color: #273E61;
        color: var(--white);
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        line-height: 136%;
      }
    }
  }
}
