.main-content {
  padding: 20px 0 0 0;
  min-height: 100vh;
  overflow-y: scroll;
}

.page-content {
  padding: 56px 2px 2px 2px;
}

.page-section {
  margin-bottom: 83px;
}

.sync-modal {
  min-width: 700px;

  .modal-content {
    padding: 50px;
  }
}

.container-fluid {
  padding-left: 0.6rem;
}
