.dgPageWrapper{
  padding-left: 2rem;
  padding-right: 2rem;

  .SubHeading{
    font-weight: 400;
    font-size: 15px;
    line-height: 20.4px;
  }

  .DescriptiveButtonsContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 31px;

    .dtb-wrapper{
      width: 340px;
      box-shadow: 0px 10px 20px 0px #3A46541F;
    }

    .top-content{
      background-color: #F9F5EE !important;
    }

    .title{
      margin-top: auto;
      margin-bottom: auto;
      font-size: 15px;
      font-weight: 600;
      line-height: 20.4px;
      color: #253746;
      max-width: 100%;
      padding-right: 0;
    }

    .description{
      font-size: 13px;
      font-weight: 400;
      color: #54565B;

    }

    .classification{
      display: none;
    }
  }
}
