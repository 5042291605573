.digital-form-contact-information-form {
  // background-color: #FFFFFF;
  // padding: 28px;
  // box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  h2 {
    color: var(--Core_Primary-dark-blue);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 136%; /* 20.4px */
  }
  p {
    color: var(--cool_gray-body_text);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 17.68px */
  }

  form {
    display: flex;
    //width: 589px
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      label {
        margin: 0;
        color: var(--Core_Primary-dark-blue);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 17.68px */
      }

      input {
        width: 100%;
        display: flex;
        padding: 11px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        //border: 1px solid var(--gray-field-stroke);
        border: 1px solid lightgray;
      }

    }

    .radio-button {

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
          width: 20px;
          height: 20px;
          border-radius: 12px;
          border: 1px solid var(--core-primary-50);
        }

        label{
          margin-left: 12px;
        }

        input[type='radio']:checked:after {
          width: 20px;
          height: 20px;
          border-radius: 15px;
          position: relative;
          background-color: var(--Blue-7710C);
          content: '';
          display: inline-block;
          visibility: visible;
        }
      }
    }

    .select-data-element {
      display: flex;
      padding: 11px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      border: 1px solid lightgray;
      width: 280px;
    }

    .select-no-data-element {
      display: none;
    }

    button {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: var(--Blue-7710C);
      border: none;
      color: #FFFFFF;
    }
  }
  .errorText {
    display: block;
    color: #ff0000;
    font-size: 12px;
    border: none;
  }
}

.digital-form-contact-information-display {
  background-color: #FFFFFF;
  padding: 28px;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);

  .header {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    h2 {
      color: #253746;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 136%; /* 21.76px */
    }
    p {
      color: #54565B;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 136%; /* 17.68px */
    }
  }

  .data-filled-form {

    .wrapper {
      display: flex;
      width: 100%;
      padding: 20px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid lightgray;
    }

    div {
      width: 100%;
      display: flex;
      align-items: center;

      label {
        width: 40%;
        color: var(--cool_gray-body_text);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%; /* 17.68px */
      }
      h2 {
        color: var(--Core_Primary-dark-blue);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 17.68px */
      }
    }
  }

  .edit-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-7710C);
    border: none;
    color:  #FFFFFF;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .add-new-contact-section {
    display: flex;
    height: 68px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 40px;

    p {
      color: #54565B;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 136%; /* 17.68px */
    }

    .add-contact-btn {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: var(--Core_Primary-dark-blue);
      border: none;
      color: #FFFFFF;
    }
  }
}
